import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout"
import CategoryList from "../../components/CategoryList"
import '../../index.css';
import Posts from "../../components/Posts"

const IndexPage = ({data}) => {
  return(<>
  <Layout location="Industry News" title={'Industry News'}>
    <section className="cw-bg-bluegradient w-100 h-44 text-center mb-10">
        <div className="font-bold text-white text-5xl align-middle py-14 ">Industry news</div>
    </section>
    <div className="" style={{ margin: `0 auto`, maxWidth: 1000, padding: `0 1rem` }}>
      <div className="lg:flex lg:inline-flex">
        <div className="">   
          <h1 className="font-bold text-3xl pb-2">All News</h1>
          <hr/>
          <Posts data={data} />
        </div>
        <hr className="mt-5 block lg:hidden"/>
        <div className="lg:ml-10" style={{width:200}}>
          <h3 className="font-bold text-lg"> Categories</h3>
          <CategoryList/>
        </div>
      </div>
    </div>
  </Layout>
</>)}
export default IndexPage

export const pageQuery = graphql`
query MyQuery {
  allMdx(sort: {order: DESC, fields: frontmatter___date}) {
    pageInfo {
      perPage
    }
    edges {
      node {
        id
        frontmatter {
          title
          date
        }
        slug
        excerpt
      }
    }
  }
}

`